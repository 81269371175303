.poppins-regular {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
}

.merienda {
    font-family: "Merienda" !important;
    font-optical-sizing: auto;
    font-style: normal;
    color: #0777AB !important;
}

.cursor {
    cursor: pointer;
}

.font-18 {
    font-size: 18px;
}

.font-50 {
    font-size: 50px;
}

.font-24 {
    font-size: 24px;
}

.text-blue {
    color: #0777AB !important;
}

.w-65 {
    width: 65%;
}

.bg-blue {
    background-color: #0777AB;
}

.animation {
    animation: 1.2s ease-out infinite zoom-in-zoom-out2;
}

@keyframes zoom-in-zoom-out2 {

    0%,
    100% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.1, 1.1);
    }
}

/* Placeholder color */
.custom-input input::placeholder {
    color: #CDCDCD;
    /* Placeholder color */
}

/* Error message style */
.error-message {
    color: red;
    font-size: 12px;
}

.space{
    letter-spacing: 1px;
}
// =========== Header ================
.textImg {
    display: block;
    animation: heart 2s ease infinite;
}

@keyframes heart {
    0% {
        transform: scale(0.8);
    }

    5% {
        transform: scale(1);
    }

    10% {
        transform: scale(0.8);
    }

    15% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.8);
    }

    100% {
        transform: scale(0.8);
    }
}

.textImg2 {
    display: block;
    animation: heart2 2s ease infinite;
}

@keyframes heart2 {
    0% {
        transform: scale(1);
    }

    5% {
        transform: scale(1.1);
    }

    10% {
        transform: scale(1);
    }

    15% {
        transform: scale(1.1);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    }
}

.opacity-custom {
    opacity: 0 !important;
}

// =========== Scroll-Bar ==================

::-webkit-scrollbar {
    width: 10px;
    /* width of the scrollbar */
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* color of the track */
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background: #7DBBF1;
    /* color of the thumb */
    border-radius: 5px;
    /* roundness of the thumb */
}

.content-container::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar */
}

// ==================== Hiring button ==================
.fixed-button {
    position: fixed;
    bottom: 20px;
    left: 44%;
    z-index: 1000;
}

.fixed-button button {
    padding: 10px 20px;
    background-color: rgba(199, 199, 199, 0.4);
    /* Semi-transparent E9E9E9 */
    border: none;
    border-radius: 30px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Add a subtle shadow */
    backdrop-filter: blur(16px);
    /* Blur effect on the background */
    -webkit-backdrop-filter: blur(16px);
    /* For Safari support */
    color: #000;
    /* Add text color if needed */
}


// ================== hero-Section ==============
.about-us {
    // background-image: url("../img/about-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    background-color: rgb(235, 241, 250);
    border-radius: 30px;
    border: 1px solid #EEEEEE;
}

.white-circle {
    height: 500px;
    width: 500px;
    background-color: white;
    border-radius: 50%;
}

.border-circle {
    height: 550px;
    width: 550px;
    // border: 1px solid #EEEEEE;
    border-radius: 50%;
    // background-color: white;
}

.hero-title {
    font-size: 50px;
}

.hero2-title {
    font-size: 40px;
}

.arrow-rotate-box {
    display: inline-block;
}

.arrow-rotate {
    transition: transform 0.3s ease;
    transform: rotate(-40deg);
}

.arrow-rotate-box:hover .arrow-rotate {
    transform: rotate(0deg);
}

.game1 {
    position: absolute !important;
    top: 80px;
    left: 40px;
    transform: rotate(-15deg);
    width: 115px;
}

.game2 {
    position: absolute !important;
    bottom: 130px;
    right: 30px;
    transform: rotate(-15deg);
    width: 100px;
}

.lol {
    position: absolute !important;
    top: 60px;
    left: 40px;
    transform: rotate(-15deg);
    width: 80px;
}

.prankster {
    position: absolute !important;
    bottom: 80px;
    right: 50px;
    transform: rotate(30deg);
    width: 80px;
}

.developer {
    position: absolute;
    bottom: 50px;
    right: 250px;
    animation: translateYInfinite 3s infinite;
    /* Animation with 2s duration and infinite repeat */
}


.first-div {
    animation: firstDivAnimation 8s linear infinite;
    transform: scale(1);
    opacity: 1;
    animation-delay: 3s;
}

.sec-div {
    transform: scale(0);
    opacity: 0;
    animation: secondDivAnimation 8s linear infinite;
    animation-delay: 3s;
}


@keyframes firstDivAnimation {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    30% {
        transform: scale(1);
        opacity: 1;
    }

    40%,
    90% {
        transform: scale(0);
        opacity: 0;
    }
}

@keyframes secondDivAnimation {

    90%,
    0% {
        transform: scale(0);
        opacity: 0;
    }

    40% {
        transform: scale(0);
        opacity: 0;
    }

    50%,
    80% {
        transform: scale(1);
        opacity: 1;
    }
}


@keyframes translateYInfinite {
    0% {
        transform: translateY(0);
        /* Starting position */
    }

    50% {
        transform: translateY(-25px);
        /* Move up by 50px */
    }

    100% {
        transform: translateY(0);
        /* Return to the starting position */
    }
}

.designer {
    position: absolute;
    bottom: -30px;
    right: -80px;
    animation: RotateInfinite 6s infinite;
    /* Animation with 2s duration and infinite repeat */
}

@keyframes RotateInfinite {
    0% {
        transform: translateY(0);
        /* Starting position */
    }

    25% {
        transform: translateX(20px);
        /* Move up by 50px */
    }

    50% {
        transform: translateY(20px);
        /* Return to the starting position */
    }

    75% {
        transform: translateX(-20px);
        /* Return to the starting position */
    }

    100% {
        transform: translateY(0px);
        /* Return to the starting position */
    }
}

// ====================About-us ===================
.about-bg {
    background-image: url("../img/about-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    // border-radius: 30px;
    background-color: rgb(188, 212, 247);
    // border: 1px solid #EEEEEE;
}

@keyframes slideInFromLeft {
    0% {
        transform: translateY(150px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes scaleIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.image-animate {
    opacity: 0;
    transform: scale(0);
    transition: opacity 2.5s ease, transform 1.5s ease 1s;
}

.image-animate.visible {
    opacity: 1;
    transform: scale(1);
}

.text-animate {
    opacity: 0;
    animation: slideInFromLeft 1s ease forwards 1s;
    animation-play-state: paused;
}

.text-animate.visible {
    animation-play-state: running;
}

// ================= Vision , Mission ======================
.about-box{
    width: 75px;
    height: 75px;
    line-height: 75px;
    // background-color: rgb(250, 223, 195);
    border: 1px dashed #0777AB ;
    border-radius: 8px;
    text-align: center;
    position: absolute;
    top: -40px;
}

.main-box {
    perspective: 1000px; /* Creates a 3D effect for the flip */
}

.about-box img {
    transition: transform 0.5s linear; /* Smooth transition for flip effect */
    transform-style: preserve-3d; /* Ensures the 3D effect works properly */
}

.main-box:hover .about-box img {
    animation: none; /* Remove animation */
    transform: rotateY(180deg); /* Apply the flip */
}


//   ================== Footer ====================
.fixed-top-btn {
    position: fixed;
    bottom: 5px;
    right: 4%;
    z-index: 1000;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 2px solid #0777AB;
    text-align: center;
    line-height: 45px;
    color: #0777AB;
    background-color: white;
}

.footer-width {
    width: 75%;
}

.common-footer {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
}

.box1 {
    background-color: #0777AB;
}

.box2 {
    background-color: #25D366;
}

.box3 {
    background-image: linear-gradient(to right, #CD0076, #DF0041);
}

.hover-box2 {
    transform: rotateZ(0deg);
    transition: all 0.2s linear !important;
}


//------- hover -------
.box1:hover {
    background-color: white;
    border: 2px solid #0777AB;
}

.hover-box:hover .hover1 {
    color: #0777AB !important;
}

.box2:hover {
    background-color: white;
    border: 2px solid #25D366;
}

.hover-box:hover .hover2 {
    color: #25D366 !important;
}

.box3:hover {
    background-image: linear-gradient(to right, white, white);
    border: 2px solid #CD0076;
}

.hover-box:hover .hover3 {
    color: #CD0076 !important;
}

.hover-box:hover .hover-box2 {
    transform: rotateZ(360deg);
}

// ================= Stack section ====================
.stack-box{
    height: 80px;
    width: 80px;
    line-height: 80px;
    text-align: center;
    border-radius: 50%;
}

.download-btn{
    width: 200px;
    height: 60px;
}

// =============== Portfolio =====================
.portfolio-bg {
    background-image: url("../img/portfolio-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    background-color: rgb(188, 212, 247);
}

.portfolio-box{
    width: 680px;
    height: 630px;
    border-radius: 30px;
    // background-color: #EBF1FA;
}

.portfolio-box2{
    width: 100%;
    height: 400px;
}

.image-box{
    transform: translateX(70px);
}
.image-box-reverse{
    transform: translateX(-130px);
}


.image-box2{
    transform: translateX(0px);
}

.animation-col{
    transform: rotate(-22deg);
}

.animation-col-reverse{
    transform: rotate(20deg);
}

.slider-container {
    white-space: nowrap;
    position: relative;
}

.slider-content {
    display: inline-flex;
    // animation: scroll 30s linear infinite;
    transform: translateY(-900px);
}

.slider-content2 {
    display: inline-flex;
    // animation: scroll2 30s linear infinite;
    transform: translateY(-400px);
}
.slider-content3 {
    display: inline-flex;
    // animation: scroll 30s linear infinite;
    transform: translateY(-900px);
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-50%);
    }
}

@keyframes scroll2 {
    0% {
        transform: translateY(-50%);
    }

    100% {
        transform: translateY(0);
    }
}

.culture-bg {
    background-image: url("../img/culture.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center -110px;
    background-color: rgb(188, 212, 247);
}

// =================== Open position ==================
.position-bg{
    background-color: rgb(235, 241, 250) !important;
    position: relative;
}

.jobseeker{
    position: absolute;
    top: 160px;
    left: 10%;
    animation: RotateInfinite 6s infinite;
}

.recruiter{
    position: absolute;
    top: 100px;
    right: 10%;
    animation: translateYInfinite 3s infinite;
}

.department{
    border-radius: 10px;
    background-color: #F6F6F6;
    padding: 8px 15px;
    color: #3f3f3f;
    font-size: 14px;
}

.position-main {
    padding: 25px 0px;
    border-radius: 12px;
    cursor: pointer;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.position-main:hover {
    background-color: #EBF1FA !important;
    border: 1px solid #7DBBF1;
}

.position-main:hover .department{
    background-color: white !important;
}

.right-icon{
    display: none;
}

.position-main:hover .right-icon{
    display: block;
}

.search-bar-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 5px 10px;
    width: 100%;
    background-color: #fff;
  }
  
  .search-input {
    border: none;
    outline: none;
    flex: 1;
    padding: 5px;
    font-size: 16px;
  }
  
  .search-button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .search-button span {
    color: #555;
  }

  .sticky {
    position: sticky;
    top: 50px;
  }
  
//   ===================== What Next ====================
.next-icon{
    height: 50px;
    width: 50px;
    line-height: 50px;
    border: 1px solid #0777AB;
    text-align: center;
    border-radius: 50%;
    margin: auto;
}

// ================= Apply Now ======================
.apply-bg {
    background-image: url("../img/apply-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    background-color: rgb(188, 212, 247);
}

.hiring-form {
    width: 65%;
}

input:focus {
    outline: none;
    border-color: transparent; 
    box-shadow: none !important; 
  }
  

.custom-file-upload {
    border: 2px dashed #79ACEC;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    position: relative;
    display: inline-block;
    border-radius: 10px;
}

.custom-file-upload .custom-file-label {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom-file-upload .custom-file-label span {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

.custom-file-upload .custom-file-label .form-text {
    font-size: 14px;
    color: #6c757d;
}



.custom-file-upload input[type="file"] {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.custom-file-upload .file-icon {
    color: #555;
    /* Adjust color as needed */
}

.input-group {
    display: flex;
    align-items: center;
}

.input-group-append {
    margin-left: -1px;
}

.input-group-text {
    background-color: #fff;
    border: 1px solid #ced4da;
    border-left: none;
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
}


// ================= Contact - us =====================
.white-box{
    min-height: 500px;
    padding: 40px;
    margin: 100px 0px;
}

.blue-box{
    min-height: 500px;
    background-color: #0777AB;
    color: #F6F6F6 !important;
    font-weight: 400;
    z-index: 1000;
    padding: 40px;
    margin: 100px 0px;

    .row {
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 20px;
    }
}

.contact-title{
    align-items: center;
}

// ================== Gallery =====================
.slider-container-culture {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 100%;
}

.slider-content-culture {
    display: flex;
    animation: scrollculture 20s linear infinite;
    min-width: fit-content;
}

@keyframes scrollculture {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-50%);
    }
}

.slider-container-culture:hover .slider-content-culture {
    animation-play-state: paused;
}


@keyframes scrollculture {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}


.slider-container-culture4 {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    cursor: pointer;
}

.slider-content-culture4 {
    display: inline-flex;
    animation: scrollculture4 10s linear infinite;
    width: 600px;
}

.slider-container-culture4:hover .slider-content-culture4 {
    animation-play-state: paused;
}

@keyframes scrollculture4 {
    0% {
        transform: translateX(-50%);
    }

    100% {
        transform: translateX(0);
    }
}

.slider-container-culture2 {
    overflow: hidden;
    position: relative;
    height: 650px; /* Adjust height as needed */
    cursor: pointer;
}

.slider-content-culture2 {
    display: flex;
    flex-direction: column;
    animation: scrollculture2 50s linear infinite;
}

.slider-container-culture2:hover .slider-content-culture2 {
    animation-play-state: paused;
}

@keyframes scrollculture2 {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-50%); /* Move up */
    }
}

.slider-container-culture3 {
    overflow: hidden;
    position: relative;
    height: 650px; /* Adjust height as needed */
    cursor: pointer;
}

.slider-content-culture3 {
    display: flex;
    flex-direction: column;
    animation: scrollculture3 50s linear infinite;
}

.slider-container-culture3:hover .slider-content-culture3 {
    animation-play-state: paused;
}

@keyframes scrollculture3 {
    0% {
        transform: translateY(-50%);
    }
    100% {
        transform: translateY(0); /* Move up */
    }
}


.custom-height {
    height: 650px; /* Default height for smaller screens */
}

@media (max-width: 992px) {  /* lg and above */
    .custom-height {
        height: auto;
    }
}



// ================== Responsive ==================
@media (max-width:576px) and (min-width:0px) {
    .game1 {
        width: 60px;
        top: 40px;
        left: 20px;
    }

    .game2 {
        width: 60px;
        bottom: 50px;
        right: 20px;
    }

    .lol {
        width: 50px !important;
        top: 30px;
        left: 20px;
    }

    .prankster {
        width: 50px !important;
        bottom: 50px;
        right: 20px;
    }

    .position-main:hover .right-icon{
        display: none;
    }

    .jobseeker{
        width: 100px;
    }
    
    .recruiter{
        width: 100px;
    }
}

@media (max-width:930px) and (min-width:0px) {
    .developer {
        bottom: 80px;
        left: 210px;
        z-index: 1000;
    }

    .designer {
        top: -120px;
        right: 80px;
        z-index: 1000;
    }

    .footer-width {
        width: 100%;
    }

    .hiring-form {
        width: 95%;
    }
}

// ========================================
@media (max-width:1350px) and (min-width:1199px) {
    .white-circle {
        height: 450px;
        width: 450px;
    }

    .border-circle {
        height: 550px;
        width: 550px;
    }
    .image-box-reverse{
        transform: translateX(-170px);
    }
}

@media (max-width:1440px) and (min-width:1080px) {
    .white-box{
        position: absolute;
        top: 180px;
        width: 75%;
    
        .white-mini{
            width: 70%;
        }
    }
    
    .blue-box{
        position: absolute;
        width: 29%;
        top: 250px;
        right: 80px;
    }

    .contact-title{
        align-items: start;
    }
}

@media (min-width:1441px) {
    .white-box{
        position: absolute;
        top: 180px;
        width: 75%;
    
        .white-mini{
            width: 60%;
        }
    }
    
    .blue-box{
        position: absolute;
        width: 29%;
        top: 250px;
        right: 80px;
    }

    .contact-title{
        align-items: start;
    }
}



@media (max-width:1198px) and (min-width:849px) {
    .white-circle {
        height: 390px;
        width: 390px;
    }

    .border-circle {
        height: 480px;
        width: 480px;
    }

    .game1 {
        width: 100px;
        top: 85px;
        left: 20px;
    }

    .game2 {
        width: 100px;
        bottom: 85px;
        right: 20px;
    }

    .lol {
        width: 60px;
        top: 85px;
        left: 40px;
    }

    .prankster {
        width: 60px;
        bottom: 85px;
        right: 50px;
    }

    .image-box-reverse{
        transform: translateX(-270px);
    }
}

@media (max-width:850px) and (min-width:605px) {
    .fixed-button {
        left: 40%;
    }

    .hero-title {
        font-size: 45px;
    }

    .white-circle {
        height: 390px;
        width: 390px;
    }

    .border-circle {
        height: 480px;
        width: 480px;
    }

    .game1 {
        top: 80px;
        left: 40px;
        width: 90px;
    }

    .game2 {
        bottom: 80px;
        right: 40px;
        width: 90px;
    }

    .lol {
        top: 60px;
        left: 60px;
        width: 70px;
    }

    .prankster {
        bottom: 80px;
        right: 50px;
        width: 70px;
    }
}

@media (max-width:604px) and (min-width:450px) {
    .fixed-button {
        left: 35%;
    }

    .hero-title {
        font-size: 35px;
    }

    .white-circle {
        height: 340px;
        width: 340px;
    }

    .border-circle {
        height: 410px;
        width: 410px;
    }

    .game1 {
        top: 60px;
        left: 30px;
        width: 80px;
    }

    .game2 {
        bottom: 60px;
        right: 30px;
        width: 80px;
    }

    .lol {
        top: 60px;
        left: 30px;
        width: 80px;
    }

    .prankster {
        bottom: 60px;
        right: 30px;
        width: 80px;
    }
}

@media (max-width:449px) and (min-width:390px) {
    .fixed-button {
        left: 30%;
    }

    .hero-title {
        font-size: 35px;
    }

    .white-circle {
        height: 280px;
        width: 280px;
    }

    .border-circle {
        height: 350px;
        width: 350px;
    }
}

@media (max-width:389px) and (min-width:0px) {
    .fixed-button {
        left: 25%;
    }

    .hero-title {
        font-size: 30px;
    }

    .white-circle {
        height: 260px;
        width: 260px;
    }

    .border-circle {
        height: 300px;
        width: 300px;
    }
}